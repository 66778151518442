<template>
  <ag-modal-container
    v-if="isUpdateMeDialogOpen"
    title="Compte utilisateur"
    width="800px"
    @close="close"
  >
    <user-form
      v-if="value"
      v-model="value"
      :optional-password="true"
      @validate="isValid = $event"
    />

    <template v-slot:footer>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="!isValid || !value"
        color="primary"
        class="ml-5"
        @click="update"
        v-text="'Modifier'"
      />
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import UserForm from '../components/UserForm'

export default {
  name: 'UpdateMeContainer',
  components: {
    AgModalContainer,
    UserForm,
  },
  data: () => ({
    isValid: false,
    loading: false,
    value: null,
  }),
  computed: {
    ...mapGetters('me', {
      userId: 'userId',
    }),
    ...mapGetters('ui', {
      isUpdateMeDialogOpen: 'isUpdateMeDialogOpen',
    }),
  },
  watch: {
    isUpdateMeDialogOpen: {
      immediate: true,
      async handler (value) {
        if (value) this.value = await Repositories.me.get()
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
      setUpdateMeDialog: 'setUpdateMeDialog',
    }),
    ...mapActions('me', {
      refreshMe: 'refreshMe',
    }),
    async update () {
      this.loading = true
      try {
        await Repositories.me.updateMe(this.value)
        await this.refreshMe()
        this.showSuccessNotification(`La modification de ${this.value.login} est validé`)
        this.close()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    close () {
      this.setUpdateMeDialog(false)
    },
  },
}
</script>
