<template>
  <ag-modal-container
    v-if="isCheckUserDialogOpen"
    title="Vérification de l'utilisateur"
    width="500px"
    no-content-padding
    @close="close"
  >
    <v-card flat class="pa-4">
      <v-card-text class="text-center">
        <h2>Êtes vous bien {{ fullname }} ?</h2>
      </v-card-text>
    </v-card>
    <template v-slot:footer>
      <v-spacer />
      <v-btn
        color="secondary"
        @click="notGoodUser"
        v-text="'Non'"
      />
      <v-btn
        color="accent"
        class="ml-5"
        @click="updateLastVerification"
        v-text="'Oui'"
      />
      <v-spacer />
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AgModalContainer from 'src/components/common/AgModalContainer'

export default {
  name: 'CheckUserContainer',
  components: {
    AgModalContainer,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('me', {
      fullname: 'fullname',
    }),
    ...mapGetters('ui', {
      isCheckUserDialogOpen: 'isCheckUserDialogOpen',
    }),
  },
  methods: {
    ...mapActions('ui', {
      setCheckUserDialog: 'setCheckUserDialog',
      setSwitchUserDialog: 'setSwitchUserDialog',
    }),
    ...mapActions('me', {
      setLastUserVerification: 'setLastUserVerification',
    }),
    async updateLastVerification () {
      this.setLastUserVerification(new Date())
      this.close()
    },
    async notGoodUser () {
      this.setSwitchUserDialog(true)
    },
    close () {
      this.setCheckUserDialog(false)
    },
  },
}
</script>
