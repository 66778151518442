<template>
  <v-menu
    bottom
    offset-y
    origin="top"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        outlined
        :color="$vuetify.theme.dark ? 'white' : 'accent'"
        class="mr-2"
        v-on="on"
        v-text="fullname"
      />
    </template>

    <v-list class="app-menu-list" flat nav>
      <v-list-item to="/" dense>
        <v-list-item-icon>
          <v-icon>list_alt</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Choix d\'un ordre de fabrication'" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense @click="setUpdateMeDialog(true)">
        <v-list-item-icon>
          <v-icon>account_circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Modifier mon compte'" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense @click="setSwitchUserDialog(true)">
        <v-list-item-icon>
          <v-icon>compare_arrows</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Changer d\'utilisateur'" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense @click="logout">
        <v-list-item-icon>
          <v-icon>power_settings_new</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Déconnexion'" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CurrentUserButtonMenu',
  computed: {
    ...mapGetters('me', {
      fullname: 'fullname',
    }),
  },
  methods: {
    ...mapActions('me', {
      logout: 'logout',
    }),
    ...mapActions('ui', {
      setUpdateMeDialog: 'setUpdateMeDialog',
      setSwitchUserDialog: 'setSwitchUserDialog',
    }),
  },
}
</script>
<style s>
.app-menu-list .v-list-item--active {
  background-color: var(--v-accent-base) !important;
  color: white;
}
</style>
