<template>
  <ag-modal-container
    v-if="isSwitchUserDialogOpen"
    title="Changer d'utilisateur"
    width="500px"
    no-content-padding
    @close="close"
  >
    <login-form
      :loading="loading"
      @send="callLoading"
    />
  </ag-modal-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AgModalContainer from 'src/components/common/AgModalContainer'
import LoginForm from '../../login/components/LoginForm'

export default {
  name: 'SwitchUserContainer',
  components: {
    AgModalContainer,
    LoginForm,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters('ui', {
      isSwitchUserDialogOpen: 'isSwitchUserDialogOpen',
    }),
  },
  methods: {
    ...mapActions('ui', {
      setCheckUserDialog: 'setCheckUserDialog',
      setSwitchUserDialog: 'setSwitchUserDialog',
    }),
    ...mapActions('me', {
      switchUser: 'switchUser',
    }),
    async callLoading (credentials) {
      this.loading = true
      try {
        await this.switchUser(credentials)

        this.setCheckUserDialog(false)
        this.close()
      }
      finally {
        this.loading = false
      }
    },
    close () {
      this.setSwitchUserDialog(false)
    },
  },
}
</script>
