<template>
  <div>
    <v-text-field
      :value="value"
      :rules="passwordRules"
      :label="passwordLabel"
      :prepend-icon="icon"
      type="password"
      @input="$emit('input', $event)"
    />

    <v-text-field
      v-if="!noConfirm"
      v-model="confirm"
      :rules="confirmRules"
      :label="confirmLabel"
      :prepend-icon="icon"
      type="password"
    />
  </div>
</template>

<script>
import FormRules from 'src/utils/form-rules'

export default {
  name: 'AgPasswordField',
  props: {
    value: {
      type: String,
      default: '',
    },
    passwordLabel: {
      type: String,
      default: 'Password',
    },
    confirmLabel: {
      type: String,
      default: 'Confirm',
    },
    optional: {
      type: Boolean,
      default: false,
    },
    noConfirm: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    confirm: '',
  }),
  computed: {
    icon () {
      return this.noIcon ? undefined : 'mdi-key'
    },
    passwordRules () {
      if (this.optional && !this.value) {
        return []
      }

      return [
        ...(this.optional ? [FormRules.required('requis')] : []),
        FormRules.minLength('Votre mot de passe doit faire 8 caractères minimum', { length: 8 }),
      ]
    },
    confirmRules () {
      return [
        ...(this.value ? [FormRules.required('requis')] : []),
        FormRules.equal('Confirmation incorrect', { compared: this.value }),
      ]
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.password = value
        this.confirm = ''
      },
    },
  },
}
</script>
